import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiResponse } from 'app/interfaces/api.interfaces';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private searchResultsSource = new BehaviorSubject<any>(null);
  searchResults$ = this.searchResultsSource.asObservable();

  constructor(private http: HttpClient) {}

  // Método para enviar los datos al servidor y actualizar los resultados
  performSearch(payload: any): void {
        this.http.post(`${environment.api_url}/search`, payload)
        .pipe( 
            map( (response: ApiResponse) => response.data)  
        )
        .subscribe(
            results => {
                this.searchResultsSource.next(results); // Actualiza los resultados
            },
            error => {
                this.searchResultsSource.next(null);
            }
        );
    }
}