import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { SearchService } from 'app/services/search.service';
import { AlmacenesFacade } from 'app/store/almacenes/almacenes.facade';
import {
  Observable,
  of,
  Subject,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';

@Component({
    selector   : 'fuse-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls  : ['./search-bar.component.scss']
})
export class FuseSearchBarComponent implements OnInit, OnDestroy
{
    collapsed: boolean;
    fuseConfig: any;

    @Output()
    input: EventEmitter<any>;

    // Private
    private _unsubscribeAll: Subject<any>;
    filteredAlmacenes: Observable<any[]>;
    almacenes$: Observable<any[]>;
    searchControl = new FormControl('');
    isAutocompleteEnabled = true;
    selectedAlmacen:any = null;
    isSelectionChange = false;
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _almacenesFacade: AlmacenesFacade,
        private router: Router,
        private searchService: SearchService
    )
    {
        this.almacenes$ = this._almacenesFacade.almacenes$;
        // Set the defaults
        this.input = new EventEmitter();
        this.collapsed = true;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        // Filtrar almacenes al escribir
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        //this._almacenesFacade.load();
        this.filteredAlmacenes = this.searchControl.valueChanges.pipe(
            debounceTime(300), // Esperar 300 ms después del último evento
            distinctUntilChanged(), // Evitar búsquedas repetidas si el valor no cambia
            switchMap(value => this.handleInputChange(value)) // Manejar el cambio de valor
          );
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (config) => {
                    this.fuseConfig = config;
                }
            );
        // Filtrar almacenes basados en la entrada
    
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Collapse
     */
    collapse(): void
    {
        this.collapsed = true;
    }

    /**
     * Expand
     */
    expand(): void
    {
        this.collapsed = false;
    }

    /**
     * Search
     *
     * @param event
     */
    /* search(event): void
    {
        this.input.emit(event.target.value);
    } */



  // Manejar la entrada del usuario
  onInput(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;

    if (this.isNumeric(inputValue)) {
      // Si es numérico, enviar directamente al servidor
      this.searchByNumber(inputValue);
    } else {
      // Si es alfanumérico, usar el autocompletado
      this.searchControl.setValue(inputValue);
    }
  }

  // Verificar si la entrada es un número
  isNumeric(value: string): boolean {
    return !isNaN(Number(value));
  }

  // Buscar por número
  private searchByNumber(number: string): void {
    // Aquí llamas a tu API o servicio con el número
    console.log('Buscar por número:', number);
    //this.input.emit(number);
  }

  // Seleccionar un elemento del autocompletado
  onSelect(selected: any): void {
    this.isSelectionChange = true; // Marcar como cambio por selección
    this.selectedAlmacen = selected; // Guardar el almacén seleccionado
    this.searchControl.setValue(selected); // Establecer solo el nombre en el input
  }
  // Método para mostrar solo el nombre del almacén en el campo de entrada
    displayFn(almacen: any): string {
        return almacen ? almacen.nombre : '';
    }
  // Método para iniciar la búsqueda al hacer clic en el botón
  onSearch(): void {
    const inputValue = this.searchControl.value;
    let payload;

    if (this.selectedAlmacen) {
      payload = { searchType: 'almacen', search: this.selectedAlmacen };
    } else if (this.isNumeric(inputValue)) {
      payload = { searchType: 'folio', search: inputValue };
    } else {
      console.log('Por favor, ingrese un número válido o seleccione un almacén.');
      return;
    }

    // Simula la llamada al servidor
    this.sendToServer(payload).subscribe(response => {
      // Redirige a la vista de detalles con los datos del servidor
      this.router.navigate(['/details']);
    });
  }
  private sendToServer(payload: any): Observable<any> {
    // Simula la respuesta del servidor
    this.searchService.performSearch(payload);
    return of({ success: true, details: 'Detalles del servidor para: ' + JSON.stringify(payload) });
  }
  private handleInputChange(value: string): Observable<any[]> {
    if (this.isSelectionChange) {
      // Restablecer el flag y no limpiar la selección
      this.isSelectionChange = false;
      return of([]);
    }
  
    if (!value) {
      // Si se borra el valor, limpiar el almacén seleccionado
      this.selectedAlmacen = null;
      return of([]);
    }
  
    // Si el valor actual no coincide con el nombre del almacén seleccionado, limpiar la selección
    if (this.selectedAlmacen && this.selectedAlmacen.nombre !== value) {
      this.selectedAlmacen = null;
    }
  
    const filterValue = value.toLowerCase();
  
    return this.almacenes$.pipe(
      map(almacenes =>
        almacenes.filter(almacen =>
          almacen.nombre.toLowerCase().includes(filterValue) || // Buscar por nombre
          almacen.serie.toLowerCase().includes(filterValue)    // Buscar por serie
        )
      )
    );
  }
}
