import { FuseNavigation } from '@fuse/types';

export const navigationCobranza: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'Aplicaciones',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        children : [
            {
                id       : 'facturacion',
                title    : 'Facturación',
                type     : 'item',
                icon     : 'credit_card',
                url: '/facturacion'
            },
            {
                id       : 'pagos',
                title    : 'Pagos',
                type     : 'collapsable',
                icon     : 'location_city',
                children: [
                    {
                        id:'facturas',
                        title: 'Facturas',
                        type: 'item',
                        url: '/pagos/factura',
                        exactMatch: true
                    },
                    {
                        id:'credcon',
                        title: 'Créditos y Contados',
                        type: 'item',
                        url: '/pagos/credcon',
                        exactMatch:true
                    }
                ],
                
            },
            {
                id       : 'notas',
                title    : 'Notas',
                type     : 'item',
                icon     : 'credit_card',
                url: '/notas'
            },
        ]
    }
    
];
