import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';

import { SearchService } from 'app/services/search.service';
import { environment } from 'environments/environment';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Component({
  templateUrl: './busqueda.component.html',
  styleUrls: ['./busqueda.component.scss']
})
export class BusquedaComponent implements OnInit {
    @ViewChild('summaryContainer', { static: false }) summaryContainer!: ElementRef;
    results: any;
    searchType:string;
    searchValue:any;
    /* cliente:Almacen;
    pipa: Pipa;
    descargas: Descarga; */

    cliente: any = null;
  descargas: any[] = [];
  totalLitros: number = 0;
  totalMonto: number = 0;
  facturasPendientes: number = 0;
  pagosPendientes: number = 0;
  promedioLitros: number = 0;
  promedioPrecio: number = 0;
  ultimaDescarga: any = null;
  descargaMasGrande: any = null;
  lightboxVisible = false;
  lightboxImage: string | null = null;
  rotation = 0;
descarga:any = null;

    constructor(private searchService: SearchService) {}
  
    ngOnInit(): void {
      // Suscríbete a los resultados de la búsqueda
      this.searchService.searchResults$.subscribe(data => {
        this.results = data;
        if(this.results){
            console.log('Los resultados de la búsqueda son:', data);
            this.searchType = data.searchType;
            this.searchValue = data.searchResult;
            this.procesaBusqueda(this.searchType, this.searchValue);
            this.procesarDatos();
        }
        if (!this.results) {
          console.warn('No se encontraron resultados.');
        }
      });
    }

    private procesaBusqueda(type, result){
        /* this.cliente = result[0].cliente;
        this.descargas = result;
        this.pipa = result[0].pipa; */
        /* if(type === 'almacen'){
            this.cliente = result[0].cliente;
            this.descargas = result;
            this.pipa = result[0].pipa;
        }else{
            this.descargas = result[0];
            this.cliente = res
        } */
    }
    procesarDatos(): void {
        const { searchResult } = this.results;
        this.searchType = this.results.searchType;
        if(this.searchType === 'folio'){
            this.descarga = this.results.searchResult[0];
            this.descarga.fotoInicial = environment.fotos_url+this.descarga.fotoInicial;
            this.descarga.fotoFinal = environment.fotos_url+this.descarga.fotoFinal;
            this.descarga.fotoTicket = environment.fotos_url+this.descarga.fotoTicket;
        }
        if (!searchResult || searchResult.length === 0) {
          this.cliente = null;
          this.descargas = [];
          return;
        }
    
        // Obtener cliente
        this.cliente = searchResult[0]?.cliente || null;
    
        // Procesar descargas
        this.descargas = searchResult.map(descarga => ({
          fecha: new Date(descarga.fecha).toISOString().split('T')[0],
          pipa: descarga.pipa.nombre,
          folio: descarga.folio,
          litros: descarga.litros,
          monto: descarga.monto,
          precio: descarga.precio,
          facturado: this.cliente.factura ? descarga.facturado : null, // Manejar facturación según cliente
          pagado: descarga.pagado,
          fotoInicial: environment.fotos_url+descarga.fotoInicial,
         fotoFinal: environment.fotos_url+descarga.fotoFinal,
         fotoTicket: environment.fotos_url+descarga.fotoTicket
        }));
        console.log(this.descargas);
        // Calcular totales y métricas
        this.calcularTotales();
        this.calcularUltimaDescarga();
        this.calcularDescargaMasGrande();
      }
      abrirLightbox(imageUrl: string): void {
        console.log(imageUrl);
        this.lightboxImage = imageUrl;
        this.lightboxVisible = true;
      }
    
      cerrarLightbox(): void {
        this.lightboxImage = null;
        this.lightboxVisible = false;
      }
    calcularTotales(): void {
    this.totalLitros = this.descargas.reduce((acc, descarga) => acc + descarga.litros, 0);
    this.totalMonto = this.descargas.reduce((acc, descarga) => acc + descarga.monto, 0);
    this.pagosPendientes = this.descargas.filter(descarga => !descarga.pagado).length;
    this.promedioLitros = this.descargas.length > 0 ? this.totalLitros / this.descargas.length : 0;
    this.promedioPrecio = this.totalLitros > 0 ? this.totalMonto / this.totalLitros : 0;
    }
    rotarImagen(angulo: number): void {
        this.rotation += angulo;
      }
    calcularUltimaDescarga(): void {
    if (this.descargas.length > 0) {
        this.ultimaDescarga = this.descargas.reduce((ultima, actual) =>
        new Date(actual.fecha) > new Date(ultima.fecha) ? actual : ultima
        );
    }
    }
    async exportarPDF(): Promise<void> {
        const pdf = new jsPDF();
        const element = this.summaryContainer.nativeElement;
    
        // Usar html2canvas para capturar el contenido
        const canvas = await html2canvas(element, {
          scale: 2, // Aumenta la calidad del renderizado
          ignoreElements: (el) =>
            el.tagName === 'A' || el.classList.contains('photo') // Ignora las fotos
        });
    
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = 190; // Ancho en mm
        const pageHeight = 297; // Alto en mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let position = 10;
    
        pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
    
        // Guarda el PDF
        pdf.save('resumen.pdf');
      }
    calcularDescargaMasGrande(): void {
    if (this.descargas.length > 0) {
        this.descargaMasGrande = this.descargas.reduce((mayor, actual) =>
        actual.litros > mayor.litros ? actual : mayor
        );
    }
    }
}
