import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as AWS from 'aws-sdk';
import {
  from,
  of,
} from 'rxjs';
import {
  catchError,
  map,
  switchMap,
} from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ApiResponse } from '../interfaces/api.interfaces';
import { AuthService } from './auth.service';

@Injectable({providedIn: 'root'})
export class ApiService {
    constructor(
        private _http: HttpClient,
        private _authService: AuthService
    ) { 
        this.createFakeDataPlantas();
    }
    public rols = [];

    public uploadDigitalSpaces(file){
        return new Promise(async(resolve, reject) => {
            const spacesEndpoint = new AWS.Endpoint('sfo3.digitaloceanspaces.com');
            const s3 = new AWS.S3({
                endpoint: spacesEndpoint,
                ...environment.do
            });

            var params = {
            Body: file.image,
            Bucket: "adgas",
            Key: file.filename,
            ContentType:"image/jpg",
            ACL: 'public-read'
            };

            s3.putObject(params, function(err, data) {
                console.log(err);
                if (err) reject(err);
                else{
                    resolve(data);
                }
            });

        })
    }
    public createFakeDataPlantas(){
        this._http.get(`${environment.api_url}/rols`).pipe(
            map((response: ApiResponse) => response.data.rols)
        ).subscribe( rols => {
            this.rols = rols;
        })
    }
    // Plantas
    public getPlantas(){
        return this._http.get(`${environment.api_url}/plantas`).pipe( 
            map( (response: ApiResponse) => response.data.plantas)  
        );
    }
    public createPlanta(planta){
        return this._http.post(`${environment.api_url}/plantas`,planta).pipe(
            map( (response: ApiResponse) => response.data.planta)
        )
    }
    public deletePlanta(id:string){
        return this._http.delete(`${environment.api_url}/planta/${id}`).pipe(
            map( (response: ApiResponse) => response.data.planta._id)
        )
    }
    public updatePlanta(planta){
        const {_id, ...updatedPlanta} = planta;
        return this._http.patch(`${environment.api_url}/planta/${_id}`,updatedPlanta).pipe(
            map( (response: ApiResponse) => {
                return response.data.planta
            })
        );
    }

      // Descargas
    public getDescargas({pipaId}){
        if(pipaId){
            return this._http.get(`${environment.api_url}/getFolios?pipa=${pipaId}`).pipe(
                map( (response: ApiResponse) => {
                    console.log('La respuesta');
                    console.log(response.data.folios);
                    return response.data.folios;
                })
                );
        }else{
            return this._http.get(`${environment.api_url}/descargas?tipo=suministro&status=set`).pipe(
                map( (response: ApiResponse) => {
                    console.log('La respuesta');
                    console.log(response.data.descargas);
                    return response.data.descargas;
                })
                );
        }
        
    }
    public getDescargaById(descargaId){
        return this._http.get(`${environment.api_url}/descarga/${descargaId}`).pipe(
            map( (response: ApiResponse) => {
                return response.data.descarga;
            })
            );
    }
    public createDescarga(descarga){
        return this._http.post(`${environment.api_url}/descargas`,descarga).pipe(
            map( (response: ApiResponse) => response.data.descargas)
        )
    }
    public deleteDescarga(id:string){
        return this._http.delete(`${environment.api_url}/descarga/${id}`).pipe(
            map( (response: ApiResponse) => response.data.descarga._id)
        )
    }
    public updateDescarga(descarga){
        const {_id, ...updatedDescarga} = descarga;
        return this._http.patch(`${environment.api_url}/descarga/${_id}`,updatedDescarga).pipe(
            map( (response: ApiResponse) => {
                return response.data.descarga
            })
        );
    }

    // Almacenes
    public getAlmacenes(){
        return this._http.get(`${environment.api_url}/almacenes`).pipe( 
            map( (response: ApiResponse) => response.data.almacenes),
            map(almacenes =>{
                return almacenes.map(almacen => ({...almacen, qr:`https://fotos.devadg.com/qr/${almacen._id}_text.png`}))
            })
        );
    }
    public createAlmacen(almacen){
        return this._http.post(`${environment.api_url}/almacenes`,almacen).pipe(
            map( (response: ApiResponse) => response.data.almacen)
        )
    }
    public deleteAlmacen(id:string){
        return this._http.delete(`${environment.api_url}/almacen/${id}`).pipe(
            map( (response: ApiResponse) => response.data.almacen._id)
        )
    }
    public updateAlmacen(almacen){
        const {_id, ...updatedAlmacen} = almacen;
        return this._http.patch(`${environment.api_url}/almacen/${_id}`,updatedAlmacen).pipe(
            map( (response: ApiResponse) => {
                return response.data.almacen
            })
        );
    }
    public getCargasAlmacen(){
        
    }

    // Pipas
    public getPipas(){
        return this._http.get(`${environment.api_url}/pipas`).pipe( 
            map( (response: ApiResponse) => response.data.pipas)  
        );
    }
    public createPipa(pipa){
        return this._http.post(`${environment.api_url}/pipas`,pipa).pipe(
            map( (response: ApiResponse) => response.data.pipa)
        )
    }
    public deletePipa(id:string){
        return this._http.delete(`${environment.api_url}/pipa/${id}`).pipe(
            map( (response: ApiResponse) => response.data.pipa._id)
        )
    }
    public updatePipa(pipa){
        
        const {_id, ...updatedPipa} = pipa;
        return this._http.patch(`${environment.api_url}/pipa/${_id}`,updatedPipa).pipe(
            map( (response: ApiResponse) => {
                return response.data.pipa
            }),
            catchError( (e:any) => {
                console.log('Error al setear pipa');
                console.log(e)
                return of(e);
            })
        );
    }

    // Cargas
    public getCargas(pipaId){
        return this._http.get(`${environment.api_url}/cargas?pipa=${pipaId}`).pipe( 
            map( (response: ApiResponse) => {
                const cargas = response.data.cargas;
                return cargas.map( (carga:any) => {
                    //get if all descargas are already validated
                    let validated = true;
                    carga.descargas.map(descarga => {
                        if(!descarga.validated) validated = false;
                    })
                    return {
                        ...carga,
                        validated,
                        fotoTicket: environment.fotos_url+carga.fotoTicket
                    }
                })
            }),
        );
    }
    public createCarga(carga){
        let promises = [];
        const nuevaCarga = {...carga}
        if(carga.fotoTicket){
            const obj = {image: carga.fotoTicket, filename:`${carga.pipa.nombre}/cargas/${carga.folio}.jpg`};
            promises.push(this.uploadDigitalSpaces(obj));
            nuevaCarga.fotoTicket = `${carga.pipa.nombre}/cargas/${carga.folio}.jpg`;
        }
        if(carga.fotoTotalizadorInicial){
            const obj2 = {image: carga.fotoTotalizadorInicial, filename:`${carga.pipa.nombre}/cargas/${carga.folio}_totalizadorInicial.jpg`};
            promises.push(this.uploadDigitalSpaces(obj2));
            nuevaCarga.fotoTotalizadorInicial = `${carga.pipa.nombre}/cargas/${carga.folio}_totalizadorInicial.jpg`;
        }
        
        return from(Promise.all(promises)).pipe(
            switchMap(() => {
                return this._http.post(`${environment.api_url}/cargas`,nuevaCarga).pipe(
                    map( (response: ApiResponse) => response.data.cargas)
                );
            })
        );
        
    }

    public deleteCarga(id:string){
        return this._http.delete(`${environment.api_url}/carga/${id}`).pipe(
            map( (response: ApiResponse) => response.data.carga._id)
        )
    }
    public updateCarga(carga){
        const {_id, ...updatedCarga} = carga;
        return this._http.patch(`${environment.api_url}/carga/${_id}`,updatedCarga).pipe(
            map( (response: ApiResponse) => {
                return response.data.carga
            })
        );
    }
    public specialUpdateDateCargas({cargaId, fecha}){
        return this._http.post(`${environment.api_url}/updateCargaDate`,{cargaId,fecha}).pipe(
            map( (response: ApiResponse) => {
                return response.data.carga
            })
        );
    }
    public getCurrentCarga(pipaId){

        return this._http.get(`${environment.api_url}/cargaOfToday?pipaId=${pipaId}`).pipe(
            map( (response: ApiResponse) => {
                return response.data.carga
            })
        );

      }

    /**
   * Función para subir la descarga de una pipa
   */
  descargaPipa(descarga: any) {
    console.log('Descargando la pipa!');

    // Extraer campos de la descarga
    const {
      fotoInicial = null,
      fotoFinal = null,
      fotoTicket,
      inicial,
      final,
      nota,
      almacen,
      litros,
      carga,
      fechaDescarga,
      pipa,
      pago,
      monto,
      location,
      precio,
    } = descarga;

    // Definir las URLs de referencia
    const referenceURLTicket = `${pipa.nombre}/descargas/${nota.folio}/nota.jpg`;
    const referenceURLInicial = fotoInicial ? `${pipa.nombre}/descargas/${nota.folio}/inicial.jpg` : '';
    const referenceURLFinal = fotoFinal ? `${pipa.nombre}/descargas/${nota.folio}/final.jpg` : '';

    // Crear arreglo de promesas para subir las fotos
    const uploadPromises = [
      this.uploadDigitalSpaces({image: fotoTicket, filename:referenceURLTicket}), // Foto obligatoria
      fotoInicial ? this.uploadDigitalSpaces({image: fotoInicial, filename:referenceURLInicial}) : Promise.resolve(null), // Foto opcional
      fotoFinal ? this.uploadDigitalSpaces({image: fotoFinal, filename:referenceURLFinal}) : Promise.resolve(null), // Foto opcional
    ];

    // Convertir las promesas de subida en un observable
    return from(Promise.all(uploadPromises)).pipe(
      switchMap(() => {
        // Crear el objeto de datos de descarga con las URLs y datos adicionales
        const dataDescarga = {
          fotoInicial: referenceURLInicial,
          fotoFinal: referenceURLFinal,
          fotoTicket: referenceURLTicket,
          nota: nota._id,
          location,
          inicial,
          fecha: fechaDescarga,
          final,
          cliente: almacen || null,
          carga,
          precio,
          monto,
          litros,
          pago,
        };

        // Enviar la información de la descarga al servidor
        return this._http.post(`${environment.api_url}/setCarga`, dataDescarga).pipe(
          map((response: any) => response.data.cargas) // Ajustar el tipo de respuesta según la API
        );
      }),
      catchError((error) => {
        console.error('Error en el proceso de subida:', error);
        return of({ error: `Error: ${error.message}` });
      })
    );
  }

    public getCargaDetail(cargaId){
        return this._http.get(`${environment.api_url}/carga/${cargaId}`).pipe( 
            map( (response: ApiResponse) =>{
                return {
                    ...response.data.carga,
                    fotoTicket: environment.fotos_url+response.data.carga.fotoTicket,
                    fotoTotalizadorInicial: environment.fotos_url+response.data.carga.fotoTotalizadorInicial,
                }
            }) 
        );
    }
    public completeCarga (payload) {
        const {cargaId, dataCarga} = payload;
        return this._http.patch(`${environment.api_url}/carga/${cargaId}`,dataCarga).pipe( 
            map( (response: ApiResponse) => response.data.carga) 
        );
     }
    public validateDescarga(changes, currentDescarga){
        try{
           let {fotoTicket = null, fotoInicial = null, fotoFinal = null, ...payload} = changes; 
           const promises = [];
            if(changes.fotoTicket){ 
                const obj = {image: changes.fotoTicket.file, filename:`${currentDescarga.pipa.nombre}/descargas/${currentDescarga.folio}/nota.jpg`};
                promises.push(this.uploadDigitalSpaces(obj));
                payload.fotoTicket = `${currentDescarga.pipa.nombre}/descargas/${currentDescarga.folio}/nota.jpg`;
            }
            if(changes.fotoInicial){
                const obj = {image: changes.fotoInicial.file, filename:`${currentDescarga.pipa.nombre}/descargas/${currentDescarga.folio}/inicial.jpg`};
                promises.push(this.uploadDigitalSpaces(obj));
                payload.fotoInicial = `${currentDescarga.pipa.nombre}/descargas/${currentDescarga.folio}/inicial.jpg`;
            }
            if(changes.fotoFinal){
                const obj = {image: changes.fotoFinal.file, filename: `${currentDescarga.pipa.nombre}/descargas/${currentDescarga.folio}/final.jpg`};
                promises.push(this.uploadDigitalSpaces(obj));
                payload.fotoFinal = `${currentDescarga.pipa.nombre}/descargas/${currentDescarga.folio}/final.jpg`;
            }
            if(promises.length > 0 ){
                return from(Promise.all(promises)).pipe( 
                    switchMap( (newPhoto) => {
                        return this._http.put(`${environment.api_url}/validateDescarga`,payload).pipe( 
                            map( (response: ApiResponse) => response.data) 
                        );
                    })
                )
            }else{
                return this._http.put(`${environment.api_url}/validateDescarga`,changes).pipe( 
                    map( (response: ApiResponse) => response.data) 
                );
            }
        }catch(e){
            console.log('Fallo en upload digital ocean');
            console.error(e);
            return of({message:e, descarga:{folio:1, tipo:'suministro'}});
        }
    }

    public validateCarga(payload){
        console.log('Validaremoos la siguiente carga');
        console.log(payload);
        return this._http.put(`${environment.api_url}/validateCarga`,payload).pipe( 
            map( (response: ApiResponse) => response.data) 
        );
    }
    public getNextFolio({pipaId, tipo}){

        return this._http.get(`${environment.api_url}/getNextFolio?pipa=${pipaId}&tipo=${tipo}`).pipe(
            map( (response: ApiResponse) => {
                return response.data.folio[0];
            })
        );

      }
    public getFolio(pipaId, filter){
        return this._http.get(`${environment.api_url}/folios?pipaId=${pipaId}&tipo=${filter}`).pipe(
            map( (response: ApiResponse) => {
                return response.data;
            })
        );
    }
    public createFolios(payload){
        return this._http.post(`${environment.api_url}/createFolios`,payload).pipe(
            map( (response: ApiResponse) => {
                return response.data;
            })
        );
    }
    public invalidateFolio(invalidateData){
        return this._http.patch(`${environment.api_url}/descarga/${invalidateData.folio}`,{invalidado: invalidateData.reason, status: 'set'}).pipe(
            map( (response: ApiResponse) => {
                return response.data.descarga;
            })
        );
      }
    //Operadores
    public getOperadores(){
        return this._http.get(`${environment.api_url}/operadores`).pipe( 
            map( (response: ApiResponse) => response.data.operadores)  
        );
    }
    public createOperador(operador){
        return this._http.post(`${environment.api_url}/operadores`,operador).pipe(
            map( (response: ApiResponse) => response.data.operador)
        )
    }
    public deleteOperador(id:string){
        return this._http.delete(`${environment.api_url}/operador/${id}`).pipe(
            map( (response: ApiResponse) => response.data.operador._id)
        )
    }
    public updateOperador(operador){
        const {_id, ...updatedOperador} = operador;
        return this._http.patch(`${environment.api_url}/operador/${_id}`,updatedOperador).pipe(
            map( (response: ApiResponse) => {
                return response.data.operador
            })
        );
    }

    //Users
    public getUsers(){
        return this._http.get(`${environment.api_url}/users`).pipe( 
            map( (response: ApiResponse) => response.data.users)  
        );
    }
    public createUser(user){
        return this._http.post(`${environment.api_url}/register`,user).pipe(
            map( (response: ApiResponse) => response.data.user)
        )
    }
    public deleteUser(id:string){
        return this._http.delete(`${environment.api_url}/user/${id}`).pipe(
            map( (response: ApiResponse) => response.data.user._id)
        )
    }
    public updateUser(user){
        const {_id, ...updatedUser} = user;
        return this._http.patch(`${environment.api_url}/user/${_id}`,updatedUser).pipe(
            map( (response: ApiResponse) => {
                return response.data.user
            })
        );
    }

    //Valvulas
    public getValvulas(){
        return this._http.get(`${environment.api_url}/valvulas`).pipe( 
            map( (response: ApiResponse) => response.data.valvulas)  
        );
    }
    public createValvula(valvula){
        return this._http.post(`${environment.api_url}/valvulas`,valvula).pipe(
            map( (response: ApiResponse) => response.data.valvula)
        )
    }
    public deleteValvula(id:string){
        return this._http.delete(`${environment.api_url}/valvula/${id}`).pipe(
            map( (response: ApiResponse) => response.data.valvula._id)
        )
    }
    public updateValvula(valvula){
        const {_id, ...updatedPlanta} = valvula;
        return this._http.patch(`${environment.api_url}/valvula/${_id}`,updatedPlanta).pipe(
            map( (response: ApiResponse) => {
                return response.data.valvula
            })
        );
    }

    //Tanques
    public getTanques(){
        return this._http.get(`${environment.api_url}/tanques`).pipe( 
            map( (response: ApiResponse) => response.data.tanques)  
        );
    }
    public createTanque(tanque){
        return this._http.post(`${environment.api_url}/tanques`,tanque).pipe(
            map( (response: ApiResponse) => response.data.tanque)
        )
    }
    public deleteTanque(id:string){
        return this._http.delete(`${environment.api_url}/tanque/${id}`).pipe(
            map( (response: ApiResponse) => response.data.tanque._id)
        )
    }
    public updateTanque(tanque){
        const {_id, ...updatedPlanta} = tanque;
        return this._http.patch(`${environment.api_url}/tanque/${_id}`,updatedPlanta).pipe(
            map( (response: ApiResponse) => {
                return response.data.tanque
            })
        );
    } 

    //Precios
    public createPrecios(precios){
        return this._http.post(`${environment.api_url}/precios`,precios).pipe(
            map( (response: ApiResponse) => response.data.precios)
        )
    }
    public updatePrecios(precios, id){
        return this._http.patch(`${environment.api_url}/precio/${id}`,precios).pipe(
            map( (response: ApiResponse) => response.data.precio)
        )
    }

    public getTodayPrecios(){
        return this._http.get(`${environment.api_url}/precios`).pipe( 
            map( (response: ApiResponse) => response.data.precios)  
        );
    }

    // Pipas Location
    public getPipasLocation(date = new Date(), pipa_id: string){
        return this._http.get(`${environment.api_url}/locations/${pipa_id}?date=${date}`).pipe(
            map( (response: ApiResponse) => response.data.locations)
        )
    }

    // Folios
    public getFolios({pipa, tipo}){
        return this._http.get(`${environment.api_url}/getFolios?pipa=${pipa}&tipo=${tipo}`).pipe(
            map( (response: ApiResponse) => response.data.folios)
        )
    }
    // Facturas
    public getFacturas(){
        return this._http.get(`${environment.api_url}/getFacturas`).pipe(
            map( (response: ApiResponse) => response.data.folios),
            map( folios => {
               return  folios.map(folio => ({
                    ...folio,
                    fotoTicket: environment.fotos_url+folio.fotoTicket
                }))
            })
        )
    }
    // Pagos
    public getPagos(){
        return this._http.get(`${environment.api_url}/getPagosFactura`).pipe(
            map( (response: ApiResponse) => response.data.folios),
            map( folios => {
               return  folios.map(folio => ({
                    ...folio,
                    fotoTicket: environment.fotos_url+folio.fotoTicket
                }))
            })
        )
    }
    // Pagos
    public getPagosContado(){
        return this._http.get(`${environment.api_url}/getPagosContado`).pipe(
            map( (response: ApiResponse) => response.data.folios),
            map( folios => {
               return  folios.map(folio => ({
                    ...folio,
                    fotoTicket: environment.fotos_url+folio.fotoTicket
                }))
            })
        )
    }


}